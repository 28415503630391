// src/axios.js
import axios from 'axios';

const instance = axios.create({
  baseURL: 'http://cs.hkd5.com:8080/prod-api', 
  // baseURL: 'http://localhost:8081', 
  timeout: 100000,
});

export default instance;
