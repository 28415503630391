import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
 

import axios from './http/axios';
// 定义路由
// 每个路由都需要映射到一个组件
const routes = [
  { path: '/', component: () => import('./components/HomePage.vue') },
  { path: '/details', component: () => import('./components/details.vue') },
  { path: '/kj', component: () => import('./components/kj.vue') },
  { path: '/record', component: () => import('./components/record.vue') }
];
 
// 创建router实例
const router = createRouter({
  history: createWebHistory(),
  routes
});
 

const app = createApp(App);

app.config.globalProperties.$axios = axios;

// 使用路由
app.use(router);
 
app.mount('#app');